import React from 'react';
import { Link } from 'gatsby';


export default function Feature(props){
    return(  
        <div className="w-full lg:w-1/2 xl:w-1/3 px-4 py-4">
            <div className="my_custome_green_bg shadow rounded">
                <div className="h-64 z-10">
                    <img src={props.img_url} className="w-full h-full object-cover overflow-hidden rounded" alt={props.img_alt} />
                </div>
            </div>
            <Link to={props.link_to} className="h-64 w-full -mt-64 p-4 transform ease-in-out duration-700 hover:bg-green-600 bg-black bg-opacity-50 rounded z-20 relative overflow-hidden flex flex-col justify-center items-center" >
                <h2 className="text-white text-center text-4xl font-bold pb-3">{props.title}</h2>
            </Link>
        </div>

    );
}