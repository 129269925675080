import React from 'react';
import Layout from '../components/layout';
import MyNavbar from '../components/navbar/navbar';
import Footer from '../components/footer';
import Helmet from 'react-helmet';
import Feature from '../components/feature';
import { Link } from 'gatsby';

import byggnadssmide_img from '../images/byggnadssmide/byggnadssmide_xs.jpg';
import tillverkning_img from '../images/tillverkning/tillverkning_xs.jpg';
import montering_img from '../images/montering/montering_xs.jpg';
import logistik_img from '../images/logistik/logistik_xs.jpg';
import termisk_img from '../images/termisk/termisk_xs.jpg';
import blastring_img from '../images/blastring/blastring_xs.jpg';
import malning_img from '../images/malning/malning_xs.jpg';
import lager_img from '../images/lager/lager_xs.jpg';
import uthyrning_img from '../images/about-top_xs.jpg';

import favicon from '../images/favicon.ico';

function Tjanster() {
    return (
        <Layout>
            <Helmet>
                <title>Tjänster - Danas Plåt &amp; Smide AB</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="plåtslageri och smidesverkstad som tar uppdrag i Södertälje, Stockholm, Mälarområdet, Södermanland och Uppland." />
                <meta name="keywords" content="plåt, smide, Blästring, Rostskyddsmålning, Termisk Sprutning" />
                <meta property="og:title" content="Tjänster - Danas Plåt och Smide AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://danasplat.se/tjanster" />
                <link rel="cannonical" href="https://danasplat.se/tjanster" />
            </Helmet>

            <MyNavbar active_page="tjanster" />
            <section className=" py-24">
              <div className="container mx-auto">
                <div className="container mx-auto px-4">
                    <h1 className="text-3xl md:text-4xl font-extrabold text-center text-green-700">Våra Tjänster</h1>
                    <p className="text-gray-600 my-6 mx-auto w-full md:w-2/3 lg:w-1/2">Vi har ett brett utbud av tjänster inom plåt och smidesarbeten och vänder oss till både byggare och privatpersoner. Vi erbjuder även tjänster inom blästring.</p>
                    <p className="text-gray-600 mx-auto w-full md:w-2/3 lg:w-1/2">Vi är kostnadseffektiva och har höga säkerhetskrav på våra utförda arbeten. För en ständig förbättring av vår verksamhet och våra uppdrag utför vi löpande mätningar och noggranna kvalitetskontroller.</p>
                </div>
                <div className="w-full md:w-5/6 mx-auto flex flex-wrap mt-20">
                  <Feature 
                    img_url={byggnadssmide_img}
                    link_to="/byggnadssmide"
                    img_alt="Byggnadssmide"
                    title="Byggnadssmide"

                  />
                  <Feature 
                    img_url={tillverkning_img}
                    link_to="/svetsarbete"
                    img_alt="Svetsarbete"
                    title="Svetsarbete"
                  />
                  <Feature 
                    img_url={montering_img}
                    link_to="/montering"
                    img_alt="Montering och rivning"
                    title="Montering &amp; Rivning"
                  />
                  <Feature 
                    img_url={logistik_img}
                    link_to="/logistik"
                    img_alt="Transport och logistik"
                    title="Transport &amp; Logistik"
                  />
                  <Feature 
                    img_url={termisk_img}
                    link_to="/tillverkning"
                    img_alt="tillverkning"
                    title="Tillverkning"
                  />
                  <Feature 
                    img_url={blastring_img}
                    link_to="/blastring"
                    img_alt="Isblästring / kolsyreblästring"
                    title="Isblästring / Kolsyreblästring"
                  />
                  <Feature 
                    img_url={malning_img}
                    link_to="/malning"
                    img_alt="Rostskyddsmålning och Industrimålning"
                    title="Rostskyddsmålning &amp; Industrimålning"
                  />
                  <Feature 
                    img_url={lager_img}
                    link_to="/lager"
                    img_alt="Lagerförvaring"
                    title="Lagerförvaring"
                  />
                  <Feature 
                    img_url={uthyrning_img}
                    link_to="/uthyrning"
                    img_alt="Uthyrning och Bemanning"
                    title="Uthyrning &amp; Bemanning"
                  />
                  <div className="w-full flex justify-center items-center mt-16 pt-2">
                      <Link to="/kontakt" className="bg-red-700 text-white text-md md:text-xl p-4 rounded hover:bg-red-800">Kontakta Oss</Link>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
        </Layout>
    );
}
export default Tjanster;